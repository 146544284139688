import React, { useMemo } from 'react';
import Garland from '../star-maps/Garland';
import Butterfly from '../star-maps/Butterfly';
import Sun from '../star-maps/Sun';
import Star from '../birth-cerificates/Star';
import Rainbow from '../birth-cerificates/Rainbow';
import SunBC from '../birth-cerificates/Sun';
import Balloon from '../birth-cerificates/Baloon';
import Whale from '../birth-cerificates/Whale';

function getRandomInRange(from, to, fixed) {
    return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
}

function PosterGenerator({
    variant,
    constellationNames,
    customAttr,
    birthCertificate,
}) {
    const { title } = variant;

    const namedConstellations = constellationNames === 'yes';
    // Default
    let fontName = 'Canonatia';
    const fontAttr = customAttr.filter((o) => o.key === 'font');

    if (fontAttr.length) {
        fontName = fontAttr[0].value;
    }

    let name = '';
    const nameAttr = customAttr.filter((o) => o.key === 'name');
    if (nameAttr.length) {
        name = nameAttr[0].value;
    }

    let dob = '';
    const dobAttr = customAttr.filter((o) => o.key === 'dob');
    if (dobAttr.length) {
        dob = dobAttr[0].value;
    }

    let pob = '';
    const pobAttr = customAttr.filter((o) => o.key === 'pob');
    if (pobAttr.length) {
        pob = `${getRandomInRange(100, 180, 3)}N, ${getRandomInRange(
            -180,
            0,
            3
        )}E`;
    }

    // Default
    let color = 'pink';
    const colorAttr = customAttr.filter((o) => o.key === 'color');
    if (colorAttr.length) {
        color = colorAttr[0].value.toLowerCase();
    }

    let weight = 0;
    const weightAttr = customAttr.filter((o) => o.key === 'weight');
    if (weightAttr.length) {
        weight = weightAttr[0].value;
    }

    let height = 0;
    const heightAttr = customAttr.filter((o) => o.key === 'height');
    if (heightAttr.length) {
        height = heightAttr[0].value;
    }

    let tob = 0;
    const tobAttr = customAttr.filter((o) => o.key === 'tob');
    if (tobAttr.length) {
        tob = tobAttr[0].value;
    }

    const garland = useMemo(
        () => (
            <Garland
                namedConstellations={namedConstellations}
                fontName={fontName}
                name={name}
                dob={dob}
                pob={pob}
                color={color}
            />
        ),
        [name, fontName, dob, pob, namedConstellations, color]
    );

    const butterfly = useMemo(
        () => (
            <Butterfly
                namedConstellations={namedConstellations}
                fontName={fontName}
                name={name}
                dob={dob}
                pob={pob}
                color={color}
            />
        ),
        [name, fontName, dob, pob, namedConstellations, color]
    );

    const sun = useMemo(
        () => (
            <Sun
                namedConstellations={namedConstellations}
                fontName={fontName}
                name={name}
                dob={dob}
                pob={pob}
                color={color}
            />
        ),
        [name, fontName, dob, pob, namedConstellations, color]
    );

    const star = useMemo(
        () => (
            <Star
                fontName={fontName}
                name={name}
                dob={dob}
                tob={tob}
                weight={weight}
                height={height}
                color={color}
            />
        ),
        [name, fontName, dob, tob, weight, height, color]
    );

    const rainbow = useMemo(
        () => (
            <Rainbow
                fontName={fontName}
                name={name}
                dob={dob}
                tob={tob}
                weight={weight}
                height={height}
                color={color}
            />
        ),
        [name, fontName, dob, tob, weight, height, color]
    );

    const sunBC = useMemo(
        () => (
            <SunBC
                fontName={fontName}
                name={name}
                dob={dob}
                tob={tob}
                weight={weight}
                height={height}
                color={color}
            />
        ),
        [name, fontName, dob, tob, weight, height, color]
    );

    const balloon = useMemo(
        () => (
            <Balloon
                fontName={fontName}
                name={name}
                dob={dob}
                tob={tob}
                weight={weight}
                height={height}
                color={color}
            />
        ),
        [name, fontName, dob, tob, weight, height, color]
    );

    const whale = useMemo(
        () => (
            <Whale
                fontName={fontName}
                name={name}
                dob={dob}
                tob={tob}
                weight={weight}
                height={height}
                color={color}
            />
        ),
        [name, fontName, dob, tob, weight, height, color]
    );

    if (!birthCertificate) {
        if (title.match(/garland/gim)) {
            return garland;
        }

        if (title.match(/butterfly/gim)) {
            return butterfly;
        }

        if (title.match(/sun/gim)) {
            return sun;
        }
    }

    if (birthCertificate) {
        if (title.match(/star/gim)) {
            return star;
        }

        if (title.match(/rainbow/gim)) {
            return rainbow;
        }

        if (title.match(/sun/gim)) {
            return sunBC;
        }

        if (title.match(/balloon/gim)) {
            return balloon;
        }

        if (title.match(/whale/gim)) {
            return whale;
        }
    }

    return <></>;
}

PosterGenerator.defaultProps = {
    birthCertificate: false,
};

export default PosterGenerator;
