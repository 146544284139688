import * as React from 'react';

export default function Footer({ weight, height, tob, dob }) {
    if (!weight && !height && !tob && !dob) {
        return '';
    }

    return (
        <svg
            x="38%"
            y="86%"
            width="400px"
            height="250px"
            className="map-footer"
        >
            <g>
                <path
                    style={{ transform: 'scale(0.3) translate(80%, 30%)' }}
                    fill="#e0a080"
                    d="M49.6 19.7c2-2.8 4.3-5.1 7.1-7C61.9 9.3 67.5 8 73.6 8.9c7.8 1.2 13.7 5.3 17.8 11.8 3.7 5.9 4.8 12.5 4 19.4-.9 7.6-4.8 13.8-9.6 19.5-5.2 6.1-11.2 11.2-17.2 16.4l-15 12.9c-2.5 2.2-5.3 2.3-7.7.2-8.1-7-16.1-13.9-24.1-21-4.8-4.2-9.1-8.9-12.7-14.2-4.4-6.7-6.4-14-5.2-22.1C5 24.3 8.3 18.1 14.4 13.5c7.7-5.8 19.6-6.8 28.1-.8 2.7 1.9 5 4.2 7.1 7zm.2 11.9c-1.1-1.4-2.1-2.9-3.1-4.3-2-2.7-3.9-5.6-6.5-7.8-7.7-6.7-20.1-4.6-25.6 3.7-5.3 8-5.3 16.4-.9 24.8 2.4 4.4 5.6 8.3 9.2 11.6 6.8 6.3 13.8 12.3 20.7 18.5 2 1.7 4 3.4 5.9 5.1.2-.2.4-.3.5-.4l20.1-17.4c4.6-4 9-8.2 12.6-13.1 3.5-4.7 5.9-9.7 5.9-15.7 0-6.3-1.7-12-6.5-16.3-4.8-4.3-10.5-5.7-16.7-4.1-3.9 1-6.7 3.6-9.1 6.6-2.2 2.8-4.3 5.8-6.5 8.8z"
                />
                <text x="50%" y="16%" fill="#e0a080">
                    {dob}
                </text>
                <path
                    style={{ transform: 'scale(0.3) translate(90%, 115%)' }}
                    fill="#e0a080"
                    d="M53.2 46h12c1.2 0 2.3.3 3.1 1.2.9 1.1 1.2 2.3.6 3.7-.6 1.4-1.7 2.2-3.1 2.2-5.4.1-10.9.1-16.3 0-2 0-3.4-1.6-3.5-3.6V27.7c0-2.1 1.6-3.6 3.6-3.6s3.5 1.5 3.6 3.7V46z"
                />
                <path
                    style={{ transform: 'scale(0.3) translate(90%, 115%)' }}
                    fill="#e0a080"
                    d="M49.6 4.2c25 0 45.4 20.4 45.4 45.5C95 74.6 74.5 95 49.6 95c-25 0-45.4-20.4-45.4-45.5.1-24.9 20.5-45.3 45.4-45.3zm38.3 45.4C88 28.5 71 11.5 49.7 11.3c-21-.2-38.4 17.2-38.3 38.3.1 21.2 17.1 38.2 38.3 38.2 21 .1 38.1-17 38.2-38.2z"
                />
                <text x="50%" y="42%" fill="#e0a080">
                    {tob}
                </text>
                <path
                    style={{ transform: 'scale(0.3) translate(70%, 190%)' }}
                    fill="#e0a080"
                    d="M26 96c-2 0-4-.6-5.7-1.9-3-2.2-4.6-5.9-3.9-9.6l3.5-20.7c.1-.4-.1-.9-.4-1.2L4.3 48.1c-2.7-2.6-3.7-6.5-2.5-10C3 34.5 6 31.9 9.7 31.4l20.8-3.1c.5-.1.8-.4 1-.8l9.2-18.9c1.6-3.4 5-5.5 8.8-5.5 3.7 0 7.1 2.1 8.8 5.4l9.4 18.8c.2.4.6.7 1 .8L89.5 31c3.7.5 6.8 3.1 7.9 6.6 1.2 3.6.2 7.4-2.4 10L80 62.5c-.3.3-.5.8-.4 1.2l3.6 20.7c.6 3.7-.8 7.4-3.9 9.6-3 2.2-7 2.5-10.3.8L50.4 85c-.4-.2-.9-.2-1.3 0l-18.5 9.8c-1.5.9-3 1.2-4.6 1.2zm23.5-84.4c-.3 0-.9.1-1.2.8L39 31.2c-1.4 2.9-4.2 4.9-7.4 5.4l-20.8 3.1c-.7.1-1 .6-1.1.9-.1.3-.2.9.4 1.4l15.1 14.6c2.3 2.2 3.4 5.5 2.8 8.7L24.6 86c-.1.7.3 1.2.6 1.3.3.2.8.4 1.4.1l18.5-9.8c2.9-1.5 6.3-1.5 9.1 0l18.6 9.7c.7.3 1.2.1 1.4-.1.3-.2.7-.6.5-1.4l-3.6-20.7c-.6-3.2.5-6.4 2.8-8.7l15-14.7c.5-.5.4-1.1.3-1.4-.1-.3-.4-.8-1.1-.9l-20.8-2.9c-3.2-.5-6-2.4-7.4-5.3l-9.4-18.8c-.1-.7-.7-.8-1-.8z"
                />
                <text x="50%" y="65%" fill="#e0a080">
                    {height} {weight}
                </text>
            </g>
        </svg>
    );
}
