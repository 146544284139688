const isValid = (attr, requiredFields = ['name', 'pob', 'dob']) => {
    const leftOver = attr.reduce((prev, curr) => {
        const idx = prev.indexOf(curr.key);
        if (idx > -1) {
            prev.splice(idx, 1);
        }
        return prev;
    }, requiredFields);

    return leftOver.length === 0;
};

export default isValid;
