/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Carousel from '../components/Carousel';
import StarMapForm from '../components/posters/StarMapProduct';
import BirthCertificateForm from '../components/posters/BirthCertificateProduct';

const StarMapTemplate = ({ fullSize, images, carousel, frames }) => {
    const reviews = [
        <div className="card review">
            <div className="card-content">
                <div className="content">
                    I just received the Magic Star Map and it’s beautiful! I purchased it for my friend and her newborn and can’t wait to give it to her. The print is of the highest quality and delivery was fast. I highly recommend it!
                </div>
            </div>
            <footer className="card-footer">
                <p className="monday-picnic has-text-weight-normal is-size-1 orange">
                    Alice
                </p>
            </footer>
        </div>,
        <div className="card review">
            <div className="card-content">
                <div className="content">
                    Wow! The print is beautiful. It is of great quality and such a great reminder of my Oliver’s birth. Thank you.
                </div>
            </div>
            <footer className="card-footer">
                <p className="monday-picnic has-text-weight-normal is-size-1 orange">
                    Julia
                </p>
            </footer>
        </div>,
        <div className="card review">
            <div className="card-content">
                <div className="content">
                    This is the best thing I could buy for my nursery. Can’t wait to hang it up! I love the paper it's printed on. I strongly recommend The Magic Star Map.
                </div>
            </div>
            <footer className="card-footer">
                <p className="monday-picnic has-text-weight-normal is-size-1 orange">
                    Sarah
                </p>
            </footer>
        </div>
    ];

    const exampleProjects = carousel.map((item) => (
        <div className="m-2">
            <PreviewCompatibleImage
                imageInfo={{
                    image: item.node,
                }}
            />
        </div>
    ));

    const mockups = fullSize.map((item) => (
        <PreviewCompatibleImage
            imageInfo={{
                image: item.node,
            }}
        />
    ));

    return (
        <div className="star-map-container">
            <section className="hero">
                <Carousel
                    slides={mockups}
                    disableButtonsControls={false}
                    disableDotsControls
                    infinite
                    responsive={{
                        1024: {
                            items: 1,
                        },
                    }}
                />
            </section>

            <section className="is-medium section--pastel">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-3-desktop">
                            <div className="column has-text-centered">
                                <PreviewCompatibleImage
                                    imageInfo={{
                                        image: images[0].node,
                                    }}
                                />
                            </div>
                            <div className="column has-text-centered-mobile mainpitch is-offset-1">
                                <h1 className="sitteroo-h2 has-text-left">
                                    <p className="sitteroo-monday-picnic orange">
                                        Magic Star Map
                                    </p>
                                </h1>

                                <p className="subtitle orange has-text-justified is-desktop pt-5 pb-5 content">
                                    This is an interactive star map gift that shows where in the universe your baby was born.
                                    It will also show the date and time of birth, so they can see how far away they were from home when they arrived on Earth.
                                    <br />
                                    <br />
                                    The Magic Star Map Gift is designed using
                                    the astronomical software, and
                                    perfectly reproduces star constellations in
                                    the sky from any date, time and place on
                                    Earth. There’s no better way to celebrate
                                    the birth of your little one than Star Map Gift. A personalised
                                    birth print is not only a beautiful nursery
                                    decoration but also an unforgettable
                                    lifetime gift.
                                    <br />
                                    <br />
                                    The choice of unique design and the neutral
                                    colour palette will harmonise well with your
                                    nursery. Are you looking for a perfect
                                    present for a mom and her baby? The Magic
                                    Star Map will be a great memento of this
                                    extraordinary moment when not only a child
                                    is born, but also a woman as a mother.
                                    <br />
                                    <br />
                                    There is much more you can do to make the
                                    room stylish, fun and homely. Design your
                                    own custom poster today and enjoy your new
                                    baby room interior in a few days.
                                </p>
                                <Link
                                    className="sitteroo-button bg-salmon is-pulled-right"
                                    to="#star-map-creator"
                                >
                                    Create a Star Map
                                </Link>
                                <div className="is-clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="is-medium section--dark-salmon white">
                <div className="hero-body">
                    <div className="container">
                        <h2 className="sitteroo-h2">
                            <p className="sitteroo-monday-picnic has-text-centered">
                                What makes our Magic Star Map Gift so special?
                            </p>
                        </h2>
                        <div className="columns">
                            <div className="item has-text-centered column is-one-fourth">
                                <div className="block">
                                    <PreviewCompatibleImage
                                        imageInfo={{
                                            image: frames[0].node,
                                        }}
                                    />
                                </div>
                                <div className="block">
                                    <p>
                                        Own a piece of the sky and give your
                                        space a new character. Choose from 14
                                        different patterns, colours, fonts and
                                        graphics and design your favourite one.
                                    </p>
                                    <br />
                                    <p>
                                        This is a great opportunity to interact
                                        with art at home created by yourself.
                                    </p>
                                </div>
                            </div>
                            <div className="item has-text-centered column is-one-fourth">
                                <div className="block">
                                    <PreviewCompatibleImage
                                        imageInfo={{
                                            image: frames[1].node,
                                        }}
                                    />
                                </div>
                                <div className="block">
                                    <p>
                                        We manually design your artwork. Using
                                        real data processing software you will
                                        see exactly how the sky looked like when
                                        your baby was born. Or in any other
                                        important moment for you.
                                    </p>
                                    <br />
                                    <p>
                                        Personalise your text by adding name,
                                        dedication or quote, and make the print
                                        and room decoration even more unique.
                                    </p>
                                </div>
                            </div>
                            <div className="item has-text-centered column is-one-fourth">
                                <div className="block">
                                    <PreviewCompatibleImage
                                        imageInfo={{
                                            image: frames[2].node,
                                        }}
                                    />
                                </div>
                                <div className="block">
                                    <p>
                                        The poster is made of high-quality 200gsm tear and water-resistant Matte Paper,
                                        which guarantees durability and clarity of colours.
                                        Our prints have sharp and vibrant colours which liven up any interior design.
                                        Poster is carefully packed in parchment paper and cardboard tubes.
                                    </p>
                                    <br />
                                    <p>
                                        Makes it the same as a perfect
                                        personalised gift for every parent.
                                    </p>
                                </div>
                            </div>
                            <div className="item has-text-centered column is-one-fourth">
                                <div className="block">
                                    <PreviewCompatibleImage
                                        imageInfo={{
                                            image: frames[3].node,
                                        }}
                                    />
                                </div>
                                <div className="block">
                                    <p>
                                        The dimensions of the poster are: A2: 42
                                        cm x 59.4 cm or B2: 50 cm x 71 cm. We
                                        choose not to make smaller sizes to
                                        ensure you get the premium quality print
                                        which brings out the best in your
                                        poster.
                                    </p>
                                    <br />
                                    <p>
                                        Due to its form, the poster can be part
                                        of a larger gallery or decorate your
                                        space by yourself.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="is-medium section--pastel">
                <div className="hero-body">
                    <div className="container">
                        <h2 className="sitteroo-h2 orange">
                            <p className="sitteroo-monday-picnic has-text-left">
                                    Reviews
                            </p>
                        </h2>
                        <Carousel slides={reviews} disableButtonsControls/>
                    </div>
                </div>
            </section>

            <section className="is-medium section--white" id="star-map-creator">
                <div className="hero-body">
                    <div className="container">
                        <h2 className="sitteroo-h2 orange">
                            <p className="sitteroo-monday-picnic has-text-centered">
                                Customise the Star Map
                            </p>
                        </h2>
                        <StarMapForm />
                    </div>
                </div>
            </section>

            <section className="is-medium section--pastel">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-3-desktop">
                            <div className="column has-text-centered">
                                <PreviewCompatibleImage
                                    imageInfo={{
                                        image: images[1].node,
                                    }}
                                />
                            </div>
                            <div className="column has-text-centered-mobile mainpitch is-offset-1">
                                <h2 className="sitteroo-h2 has-text-left">
                                    <p className="sitteroo-monday-picnic orange">
                                        Birth Certificate
                                    </p>
                                </h2>
                                <p className="subtitle orange has-text-justified is-desktop pt-5 content">
                                    You remember very well the day your baby was
                                    born, don&#39;t you?
                                </p>
                                <p className="subtitle orange has-text-justified is-desktop pt-2 pb-2 content">
                                    You are strong and brave and we want you to
                                    remember this every day. This beautiful
                                    personalised print will be a wonderful
                                    reminder of that amazing time when you
                                    carried a baby under your heart.
                                </p>
                                <p className="subtitle orange has-text-justified is-desktop pt-2 pb-2 content">
                                    The Birth Certificate with charming natural
                                    elements will be a great decoration of your
                                    nursery or playroom. This poster will
                                    decorate your space on its own, but also
                                    create an excellent collection with The
                                    Magic Sky Map. It is a unique gift for
                                    parents and a perfect way to celebrate the
                                    birth of their baby together.
                                </p>
                                <p className="subtitle orange has-text-justified is-desktop pb-5 content">
                                    Enter your child’s details in our creator
                                    and hang The Birth Certificate on the wall
                                    in a few days.
                                    You can add any information you&#39;d like to the map, such as your child&#39;s name, age at birth, and more.
                                    If you&#39;re not sure what information to add, just leave it blank.
                                </p>
                                <Link
                                    className="sitteroo-button bg-salmon is-pulled-right "
                                    to="#birth-certificate-creator"
                                >
                                    Create a Certificate
                                </Link>
                                <div className="is-clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section
                className="is-medium section--white"
                id="birth-certificate-creator"
            >
                <div className="hero-body">
                    <div className="container">
                        <h2 className="sitteroo-h2 orange">
                            <p className="sitteroo-monday-picnic has-text-centered">
                                Birth Certificate Creator
                            </p>
                            <BirthCertificateForm />
                        </h2>
                    </div>
                </div>
            </section>

            <section className="is-medium section--salmon">
                <div className="hero-body">
                    <div className="container">
                        <h2 className="sitteroo-h2 white">
                            <p className="sitteroo-monday-picnic has-text-left">
                                Our Posters
                            </p>
                        </h2>
                        <Carousel
                            slides={exampleProjects}
                            disableButtonsControls={false}
                            disableDotsControls
                            infinite
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};

const StarMap = ({ data }) => {
    const {
        creatorImages,
        fullSizeImages,
        carousel,
        frames,
        data: { frontmatter },
    } = data;

    return (
        <Layout>
            <Helmet titleTemplate="%s">
                <title>Personalised Star Map Gift</title>
                <meta
                    name="description"
                    content={`${frontmatter.mainpitch.description}`}
                />
                <meta
                    name="og:description"
                    content={`${frontmatter.mainpitch.description}`}
                />
            </Helmet>

            <StarMapTemplate
                fullSize={fullSizeImages.edges}
                images={creatorImages.edges}
                carousel={carousel.edges}
                frames={frames.edges}
            />
        </Layout>
    );
};

StarMap.propTypes = {
    data: PropTypes.shape({
        creatorImages: PropTypes.shape({
            edges: PropTypes.array,
        }),
        fullSizeImages: PropTypes.shape({
            edges: PropTypes.array,
        }),
        carousel: PropTypes.shape({
            edges: PropTypes.array,
        }),
        frames: PropTypes.shape({
            edges: PropTypes.array,
        }),
        data: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string,
                mainpitch: PropTypes.shape({
                    description: PropTypes.string,
                }),
            }),
        }),
    }),
};

export default StarMap;

export const StarMapPageQuery = graphql`
    query StarMapPage {
        data: markdownRemark(
            frontmatter: { templateKey: { eq: "star-map-page" } }
        ) {
            frontmatter {
                mainpitch {
                    description
                }
                title
            }
        }
        creatorImages: allFile(
            filter: {
                extension: { regex: "/(png)/" }
                name: { regex: "/(star-map-section|star-map-section)/" }
            }
            sort: { fields: name }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(
                            width: 448
                            quality: 100
                            layout: CONSTRAINED
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
        fullSizeImages: allFile(
            filter: {
                extension: { regex: "/(png)/" }
                name: { regex: "/(star-map-full-size)/" }
            }
            sort: { fields: name }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 100
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
        frames: allFile(
            filter: {
                extension: { regex: "/(png)/" }
                name: { regex: "/(frame)/" }
            }
            sort: { fields: name }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 100
                            height: 236
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
        carousel: allFile(
            filter: {
                extension: { regex: "/(png)/" }
                name: { regex: "/(star-map-carousel)/" }
            }
            sort: { fields: name }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 100
                            width: 598
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
    }
`;
