import React, { useState, useContext, useCallback, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { isEqual } from 'lodash';
import { StoreContext } from '../../context/store-context';
import { AddToCart } from '../AddToCart';
import { format, formatTime, formatDate } from '../../utils/format';
import isValid from '../../utils/validator';
import PosterGenerator from './PosterGenerator';

function ProductTemplate({ data }) {
    const { sitterooShopifyProduct } = data;
    const {
        options,
        variants,
        variants: [initialVariant],
        priceRangeV2,
    } = sitterooShopifyProduct;

    const { client, error } = useContext(StoreContext);
    const [variant, setVariant] = useState({ ...initialVariant });
    const [customAttr, setCustomAttr] = useState([
        { key: 'show_constellations', value: 'yes' },
        { key: 'font', value: 'Canonatia' },
        { key: 'color', value: 'Pink' },
    ]);

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [mapSize, setMapSize] = useState('A2');
    const [constellationNames, setConstellationNames] = useState('yes');

    const productVariant =
        client.product.helpers.variantForOptions(sitterooShopifyProduct, variant) ||
        variant;

    const [available, setAvailable] = useState(productVariant.availableForSale);

    const checkAvailability = useCallback(
        (productId) => {
            client.product.fetch(productId).then((fetchedProduct) => {
                const result =
                    fetchedProduct?.variants.filter(
                        (_variant) =>
                            _variant.id === productVariant.storefrontId
                    ) ?? [];

                if (result.length > 0) {
                    setAvailable(result[0].available);
                }
            });
        },
        [productVariant.storefrontId, client.product]
    );

    const handleOptionChange = (index, event) => {
        const { value, name } = event.target;

        if (value === '') {
            return;
        }

        const currentOptions = [...variant.selectedOptions];

        currentOptions[index] = {
            ...currentOptions[index],
            value,
        };

        const selectedVariant = variants.find((_variant) =>
            isEqual(currentOptions, _variant.selectedOptions)
        );

        if (name.match(/size/gim) !== null) {
            setMapSize(value);
        }

        setVariant({ ...selectedVariant });
    };

    const handleCustomAttributeChange = (event) => {
        const { value, name } = event.target;

        if (name === 'show_constellations') {
            setConstellationNames(value);
        }

        let customAttributes;
        let parsedValue = value;

        if (name === 'tob') {
            parsedValue = formatTime(value);
        }

        if (name === 'dob') {
            parsedValue = formatDate(value);
        }

        const newAttribute = { key: name, value: parsedValue };

        if (value === '') {
            customAttributes = [
                ...customAttr.filter((o) => o.key !== newAttribute.key),
            ];

            setCustomAttr(customAttributes);

            setButtonDisabled(!isValid(customAttributes));
            return;
        }

        customAttributes = [
            ...customAttr.filter((o) => o.key !== newAttribute.key),
            { ...newAttribute },
        ];

        setCustomAttr(customAttributes);

        setButtonDisabled(!isValid(customAttributes));
    };

    useEffect(() => {
        checkAvailability(sitterooShopifyProduct.storefrontId);
    }, [
        productVariant.storefrontId,
        checkAvailability,
        sitterooShopifyProduct.storefrontId,
    ]);

    const price = format(
        priceRangeV2.minVariantPrice.currencyCode,
        variant.price
    );

    const compareAtPrice = variant?.presentmentPrices[0]?.compareAtPrice?.amount;

    const specialPrice = compareAtPrice && format(priceRangeV2.minVariantPrice.currencyCode, compareAtPrice);


    return (
        <div className="container sky-map-form">
            <div className="columns">
                <div className="column">
                    <PosterGenerator
                        variant={variant}
                        constellationNames={constellationNames}
                        customAttr={customAttr}
                    />
                </div>
                <div className="column">
                    <div className="field is-vertical">
                        <label htmlFor="name">
                            <span className="sitteroo-label">Name:</span>
                            <div className="field">
                                <input
                                    type="text"
                                    className="input is-medium"
                                    id="name"
                                    name="name"
                                    aria-label="Name attribute"
                                    onChange={(event) =>
                                        handleCustomAttributeChange(event)
                                    }
                                    required
                                />
                            </div>
                        </label>
                    </div>

                    <div className="field is-vertical">
                        <label htmlFor="pob">
                            <span className="sitteroo-label">
                                Place on earth:
                            </span>
                            <div className="field">
                                <input
                                    type="text"
                                    className="input is-medium"
                                    id="pob"
                                    name="pob"
                                    aria-label="Place of birth attribute"
                                    onChange={(event) =>
                                        handleCustomAttributeChange(event)
                                    }
                                    required
                                />
                            </div>
                        </label>
                    </div>

                    <div className="field is-horizontal-desktop is-flex-desktop is-justify-content-space-between">
                        <div className="field">
                            <label htmlFor="dob">
                                <span className="sitteroo-label">Date:</span>
                                <input
                                    type="date"
                                    className="input is-medium"
                                    name="dob"
                                    id="dob"
                                    aria-label="Day of birth attribute"
                                    onChange={(event) =>
                                        handleCustomAttributeChange(event)
                                    }
                                    required
                                />
                            </label>
                        </div>
                        <div className="field">
                            <label htmlFor="tob">
                                <span className="sitteroo-label">Time:</span>
                                <input
                                    type="time"
                                    className="input is-medium"
                                    id="tob"
                                    name="tob"
                                    aria-label="Time of birth attribute"
                                    onChange={(event) =>
                                        handleCustomAttributeChange(event)
                                    }
                                />
                            </label>
                        </div>
                    </div>

                    {options.map(({ id, name, values }, index) => {
                        if (name === 'Style') {
                            return (
                                <div
                                    className="field is-horizontal-desktop is-flex-desktop is-justify-content-space-between"
                                    key={`${name}-${id}`}
                                >
                                    <div className="field">
                                        <div className="sitteroo-label">
                                            Choose Layout:
                                        </div>
                                        <div className="select is-medium">
                                            <select
                                                name="layout"
                                                aria-label="Variants"
                                                onChange={(event) =>
                                                    handleOptionChange(
                                                        index,
                                                        event
                                                    )
                                                }
                                            >
                                                {values.map((value) => (
                                                    <option
                                                        value={value}
                                                        key={`${name}-${value}`}
                                                        defaultValue={
                                                            initialVariant
                                                                .selectedOptions[1]
                                                                .value
                                                        }
                                                    >
                                                        {value}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="sitteroo-label">
                                            Constellation names:
                                        </div>
                                        <div className="radio-tile-group is-align-items-center is-justify-content-start">
                                            <div className="input-container">
                                                <input
                                                    id="show-constellation-names"
                                                    className="radio-button"
                                                    type="radio"
                                                    name="show_constellations"
                                                    aria-label="Hide constellation names attribute"
                                                    value="yes"
                                                    checked={
                                                        constellationNames ===
                                                        'yes'
                                                    }
                                                    onChange={(event) =>
                                                        handleCustomAttributeChange(
                                                            event
                                                        )
                                                    }
                                                />
                                                <div className="radio-tile">
                                                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                    <label
                                                        htmlFor="show-constellation-names"
                                                        className="radio-tile-label is-sr-only"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>
                                            Yes
                                            <div className="input-container ml-5">
                                                <input
                                                    id="hide-constellation-names"
                                                    className="radio-button"
                                                    type="radio"
                                                    name="show_constellations"
                                                    aria-label="Show constellation names attribute"
                                                    value="no"
                                                    checked={
                                                        constellationNames ===
                                                        'no'
                                                    }
                                                    onChange={(event) =>
                                                        handleCustomAttributeChange(
                                                            event
                                                        )
                                                    }
                                                />
                                                <div className="radio-tile">
                                                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                    <label
                                                        htmlFor="hide-constellation-names"
                                                        className="radio-tile-label is-sr-only"
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                            No
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        if (name === 'Size') {
                            return (
                                <div
                                    className="field is-horizontal"
                                    key={`${name}-${id}`}
                                >
                                    <div className="sitteroo-label">
                                        Pick size:
                                    </div>
                                    <div className="field no-min-width">
                                        <div className="control">
                                            <div className="radio-tile-group">
                                                <div className="input-container">
                                                    <input
                                                        id={`${name}--${values[0]}`}
                                                        className="radio-button"
                                                        type="radio"
                                                        name={`${name}-map`}
                                                        value={values[0]}
                                                        defaultChecked={
                                                            values[0] ===
                                                            mapSize
                                                        }
                                                        onChange={(event) =>
                                                            handleOptionChange(
                                                                index,
                                                                event
                                                            )
                                                        }
                                                    />
                                                    <div className="radio-tile">
                                                        <label
                                                            htmlFor={`${name}--${values[0]}`}
                                                            className="radio-tile-label"
                                                        >
                                                            {values[0]}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="input-container ml-5">
                                                    <input
                                                        id={`${name}--${values[1]}`}
                                                        className="radio-button"
                                                        type="radio"
                                                        // name={name}
                                                        name={`${name}-map`}
                                                        value={values[1]}
                                                        defaultChecked={
                                                            values[1] ===
                                                            mapSize
                                                        }
                                                        onChange={(event) =>
                                                            handleOptionChange(
                                                                index,
                                                                event
                                                            )
                                                        }
                                                    />
                                                    <div className="radio-tile">
                                                        <label
                                                            htmlFor={`${name}--${values[1]}`}
                                                            className="radio-tile-label"
                                                        >
                                                            {values[1]}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        return '';
                    })}

                    <div className="field is-horizontal-desktop is-flex-desktop is-justify-content-space-between">
                        <div className="field">
                            <div className="sitteroo-label">Choose font:</div>
                            <div className="select is-medium">
                                <select
                                    name="font"
                                    aria-label="Font attribute"
                                    onChange={(event) =>
                                        handleCustomAttributeChange(event)
                                    }
                                >
                                    <option>Canonatia</option>
                                    <option>Charlinada</option>
                                    <option>JosefinSans</option>
                                </select>
                            </div>
                        </div>
                        <div className="field">
                            <div className="sitteroo-label">Choose color:</div>
                            <div className="select is-medium">
                                <select
                                    name="color"
                                    aria-label="Color attribute"
                                    onChange={(event) =>
                                        handleCustomAttributeChange(event)
                                    }
                                >
                                    <option>Pink</option>
                                    <option>Peach</option>
                                    <option>Grey</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="is-flex-desktop is-justify-content-space-between is-align-items-center pb-5">
                        <h2 className="mt-5">
                            <s>{ specialPrice }</s>
                            <span className="yellow"> { price }</span>
                        </h2>

                        <AddToCart
                            variantId={productVariant.storefrontId}
                            variantName="Star Map"
                            variantPrice={variant.price}
                            quantity={1}
                            customAttr={customAttr}
                            available={available}
                            disabled={buttonDisabled}
                        />
                    </div>

                    <p className="help description">
                        * It’s important to give the exact details to generate
                        star constellations. If you are not sure, type the most
                        accurate place, date and time to your knowledge.
                    </p>

                    <p className="help description">
                        * We like surprises, so you do not see the exact view of
                        your map yet.
                    </p>
                    <p className="help description">
                        * The poster is custom-made and printed specially for
                        you. The time to prepare the poster for shipping is 2-5
                        business days, depending on the time of placing the
                        order.
                    </p>
                    <p className="help description">
                        * Free shipping for all Australia deliveries.
                    </p>
                    <p className="help description">
                        * Where can I buy a picture frame? A large selection of
                        frames you can find in IKEA, Kmart, Big W or Target.
                    </p>

                    {error && (
                        <article className="message is-danger">
                            <div className="message-header">
                                <p>Error</p>
                            </div>
                            <div className="message-body">{error}</div>
                        </article>
                    )}
                </div>
            </div>
        </div>
    );
}

export default function StarMap() {
    return (
        <StaticQuery
            query={graphql`
                query StarMap {
                    sitterooShopifyProduct(
                        shopifyId: { eq: "gid://shopify/Product/6923443077164" }
                    ) {
                        shopifyId
                        options {
                            name
                            position
                            shopifyId
                            values
                        }
                        variants {
                            availableForSale
                            storefrontId
                            title
                            price
                            presentmentPrices {
                              compareAtPrice {
                                amount
                              }
                            }
                            selectedOptions {
                                name
                                value
                            }
                        }
                        storefrontId
                        priceRangeV2 {
                            maxVariantPrice {
                                currencyCode
                                amount
                            }
                            minVariantPrice {
                                amount
                                currencyCode
                            }
                        }
                    }
                }
            `}
            render={(data) => <ProductTemplate data={data} />}
        />
    );
}
