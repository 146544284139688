import * as React from 'react';

export default function Footer({ dob, pob }) {
    if (!dob && !pob) {
        return '';
    }

    return (
        <svg
            x="32%"
            y="86%"
            width="400px"
            height="200px"
            className="map-footer"
        >
            <g>
                <path
                    style={{ transform: 'scale(0.3) translate(100%, 140%)' }}
                    fill="#e0a080"
                    d="M49.6 19.7c2-2.8 4.3-5.1 7.1-7C61.9 9.3 67.5 8 73.6 8.9c7.8 1.2 13.7 5.3 17.8 11.8 3.7 5.9 4.8 12.5 4 19.4-.9 7.6-4.8 13.8-9.6 19.5-5.2 6.1-11.2 11.2-17.2 16.4l-15 12.9c-2.5 2.2-5.3 2.3-7.7.2-8.1-7-16.1-13.9-24.1-21-4.8-4.2-9.1-8.9-12.7-14.2-4.4-6.7-6.4-14-5.2-22.1C5 24.3 8.3 18.1 14.4 13.5c7.7-5.8 19.6-6.8 28.1-.8 2.7 1.9 5 4.2 7.1 7zm.2 11.9c-1.1-1.4-2.1-2.9-3.1-4.3-2-2.7-3.9-5.6-6.5-7.8-7.7-6.7-20.1-4.6-25.6 3.7-5.3 8-5.3 16.4-.9 24.8 2.4 4.4 5.6 8.3 9.2 11.6 6.8 6.3 13.8 12.3 20.7 18.5 2 1.7 4 3.4 5.9 5.1.2-.2.4-.3.5-.4l20.1-17.4c4.6-4 9-8.2 12.6-13.1 3.5-4.7 5.9-9.7 5.9-15.7 0-6.3-1.7-12-6.5-16.3-4.8-4.3-10.5-5.7-16.7-4.1-3.9 1-6.7 3.6-9.1 6.6-2.2 2.8-4.3 5.8-6.5 8.8z"
                />
                <text
                    x="55%"
                    y="50%"
                    fill="#e0a080"
                    textAnchor="middle"
                    alignmentBaseline="central"
                >
                    {dob}
                </text>
                <text
                    x="50%"
                    y="80%"
                    fill="#e0a080"
                    alignmentBaseline="baseline"
                >
                    {pob}
                </text>
            </g>
        </svg>
    );
}
